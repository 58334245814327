import { PipelinePlugin } from '@ads/plugin-pipeline';
// import { authServicePlugin } from '../plugin';
import { RefreshUserTokenDTO } from '../types';

// Temporary disabled for EID Downtime on 2025.01.11
// export class RefreshUserTokenPlugin<T extends RefreshUserTokenDTO>
//   implements PipelinePlugin<T>
// {
//   public async execute(dto: T): Promise<T> {
//     const isTokenRefreshed = await authServicePlugin
//       .get()
//       .forceRefreshUserToken();

//     if (!isTokenRefreshed) {
//       dto.isTokenRefreshFailed = true;
//     }
//     return dto;
//   }
// }

export class RefreshUserTokenPlugin<T extends RefreshUserTokenDTO>
  implements PipelinePlugin<T>
{
  public execute(dto: T): T {
    return dto;
  }
}
