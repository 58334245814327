/* eslint-disable */
// @ts-nocheck

import { LoggerService, loggerServicePlugin } from '@/features/core/logger';
import { performanceTrackerServicePlugin } from '../plugin';
import { StopTrackingOptions, UsePerformanceTracker } from '../types';
import { PerformanceTrackerService } from '../services/performance-tracker';

const MS_AGO_THRESHOLD = 1000 * 60 * 10;

/**
 * This composable has been disabled as an attempt to solve current issues with order completion
 * @see https://aldi-sued.atlassian.net/browse/CICS-61394
 */
const usePerformanceTracker = (
  performanceTrackerService: PerformanceTrackerService = performanceTrackerServicePlugin.get(),
  loggerService: LoggerService = loggerServicePlugin.get(),
): UsePerformanceTracker => {
  const formatRunningTime = (runningTime: number) => {
    if (runningTime < 1000) {
      return `${runningTime.toFixed(2)}ms`;
    }

    if (runningTime < 1000 * 60) {
      return `${(runningTime / 1000).toFixed(2)}s`;
    }

    return `${(runningTime / (1000 * 60)).toFixed(2)}m`;
  };

  const startTracking = (key: string, threshold = 0) => {
    return;

    performanceTrackerService.startTracking(key, threshold);
  };

  const stopTracking = (
    key: string,
    options: StopTrackingOptions = {
      logSlowOperation: true,
      clearTrackingData: true,
      ignoreFaultyStop: true,
    },
  ) => {
    return;

    try {
      performanceTrackerService.stopTracking(key);
    } catch (e) {
      // Redirecting to OrderList may happen from different places, this is to avoid false positives
      if (options.ignoreFaultyStop) {
        return;
      }

      loggerService.info(`Error stopping tracking: ${key}`, e);
      return;
    }

    if (options.logSlowOperation) {
      const trackingData = performanceTrackerService.getTrackingData(key);
      const runningTime = performanceTrackerService.getRunTime(key);
      if (!trackingData || !runningTime) {
        return;
      }

      if (runningTime > trackingData.threshold) {
        loggerService.info(
          `Slow operation: ${key} stopped after ${formatRunningTime(
            runningTime,
          )}. Threshold: ${formatRunningTime(trackingData.threshold)}`,
        );
      }
    }

    if (options.clearTrackingData) {
      performanceTrackerService.clearTrackingData(key);
    }
  };

  const cancelTracking = (key: string) => {
    return;

    const runningTime = performanceTrackerService.getRunTime(key);
    performanceTrackerService.clearTrackingData(key);

    if (!runningTime) {
      return;
    }

    loggerService.info(
      `Operation: ${key} was cancelled after ${formatRunningTime(runningTime)}`,
    );
  };

  const clearOldTrackingData = () => {
    return;

    const pendingTimers: Array<[string, number]> = [];

    const performanceData = performanceTrackerService.getAllTrackingData();
    performanceData.forEach((value, key) => {
      const limitTimestamp = performance.now() - MS_AGO_THRESHOLD;

      if (value.start > limitTimestamp) {
        return;
      }

      // If the timer is already stopped, clear it without logging
      if (value.end !== undefined) {
        return performanceTrackerService.clearTrackingData(key);
      }

      const runningTime = performanceTrackerService.getRunTime(key);
      if (!runningTime) {
        return;
      }

      pendingTimers.push([key, runningTime]);
      performanceTrackerService.clearTrackingData(key);
    });

    if (pendingTimers.length === 0) {
      return;
    }

    loggerService.info(
      `There were timers that were not stopped in time (${formatRunningTime(
        MS_AGO_THRESHOLD,
      )})`,
      pendingTimers,
    );
  };

  return {
    startTracking,
    stopTracking,
    clearOldTrackingData,
    cancelTracking,
  };
};

export { usePerformanceTracker };
