import { SyncScheduler } from './sync-scheduler';
import { Sync } from '../entities';
import { getEntityByName } from '@/utils/helpers/getEntityByName';
import { stripFks } from '@/features/core/storage/fk-resolver';
import { BooleanNumber, Storage } from '@/features/core/storage';
import { SyncSchedulerService } from './sync-scheduler-service';
import { EntityRepository } from '@/features/core/entity-repository';
import { EventBus } from '@/features/core/event-bus';
import { LoggerService } from '@/features/core/logger';
import { SyncFailureEvent } from '../events';

export interface SyncSchedulerExecutor {
  executeScheduler(
    syncFilterDto?: SyncSchedulerExecutorFilterDto,
  ): Promise<Sync[] | undefined>;
}

export class SyncSchedulerExecutorFilterDto {
  constructor(
    public readonly entityType: string,
    public readonly entityId: string,
  ) {}
}

export class SyncSchedulerExecutorService implements SyncSchedulerExecutor {
  constructor(
    private syncScheduler: SyncScheduler,
    private storage: Storage,
    private entityRepository: EntityRepository,
    private eventBus: EventBus,
    private loggerService: LoggerService,
  ) {}

  public async executeScheduler(
    syncFilterDto?: SyncSchedulerExecutorFilterDto,
  ): Promise<Sync[] | undefined> {
    const syncStatuses = await this.syncScheduler.getPending(syncFilterDto);

    this.loggerService.debug(
      `Amount of uncompleted syncs: ${syncStatuses?.length || 0}`,
    );
    if (!syncStatuses || syncStatuses?.length === 0) {
      return;
    }

    const syncList: Sync[] = [];
    for (const syncStatus of syncStatuses) {
      try {
        const type = getEntityByName(syncStatus.data.entity);
        if (!type) {
          continue;
        }
        const entitySnapshot = type.from(syncStatus.data.entitySnapshot);

        if (syncStatus.data.api === 'save') {
          await this.entityRepository.save(stripFks(entitySnapshot)).completed;
        } else if (syncStatus.data.api === 'remove') {
          await this.entityRepository.remove(entitySnapshot).completed;
        } else if (syncStatus.data.api === 'removeAll') {
          await this.entityRepository.removeAll(type).completed;
        }
        syncStatus.isCompleted = BooleanNumber.True;
        syncStatus.completedAt = new Date();
        syncStatus.failReason = undefined;
        const sync = SyncSchedulerService.convertSyncStatusToSync(syncStatus);
        await this.storage.save(sync);
        syncList.push(sync);
        this.loggerService.debug(`Completed sync has been saved.
          Entity: ${syncStatus.data.entity}
          EntityId: ${String(syncStatus.data.entityId)}
          API Method: ${syncStatus.data.api}
        `);
      } catch (error) {
        syncStatus.retries += 1;
        syncStatus.failReason = JSON.stringify(error);

        const sync = SyncSchedulerService.convertSyncStatusToSync(syncStatus);
        this.loggerService.warn(
          `SyncSchedulerService ${sync.id} failed with ${String(
            sync.failReason,
          )} `,
          {
            error: syncStatus.failReason,
            data: sync.data,
          },
        );
        await this.storage.save(sync);
        if (syncStatus.retries >= 3) {
          this.eventBus.emit(new SyncFailureEvent());
        }

        throw error;
      }
    }

    return syncList;
  }
}
