import { apiPlugin } from '@/features/core/api';
import { authServicePlugin } from '@/features/core/auth';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { imageCachingQueueServicePlugin } from '@/features/imageCachingQueue';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { OrderApiClient } from './order';
import { registerOrderApi } from './orderRegister';
import { configurationServicePlugin } from '@/features/configuration';

export * from './order';
export * from './orderRegister';

export interface OrdersApiClient {
  order: OrderApiClient;
}

export const ordersApiPlugin = ProviderPluginFactory.create<OrdersApiClient>({
  key: Symbol('OrdersApiClient'),
  defaultFactory: {
    create: () => ({
      order: new OrderApiClient(
        apiPlugin.get(),
        storagePlugin.get(),
        errorPlugin.get(),
        authServicePlugin.get(),
        imageCachingQueueServicePlugin.get(),
        syncSchedulerServicePlugin.get(),
        loggerServicePlugin.get(),
        configurationServicePlugin.get(),
      ),
    }),
  },
});

export const ordersApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const { order } = ordersApiPlugin.get();
  registerOrderApi(onlineEntityRepositoryRegistryPlugin.get(), order);
});
