<template>
  <div class="base-search">
    <div
      v-if="!showSearch"
      :class="[
        'base-search__title ',
        { 'base-search__title--back ': isBackExist },
        { 'base-search__title--profile': isProfileExist },
      ]"
      data-e2e="base-search-title"
    >
      <BackButton v-if="isBackExist" :to="backTo" class="back-button" />
      <h3
        class="title ut-font_fix"
        data-e2e="header-order-label"
        @click="toggleSearch"
      >
        {{ searchTitle }}
      </h3>
      <button
        tabindex="-1"
        class="search-btn"
        @click="toggleSearch"
        data-e2e="search-button"
      >
        <BaseIcon
          width="28"
          height="28"
          :iconStroke="searchIconColor"
          :iconFill="searchIconColor"
        >
          <SearchIcon />
        </BaseIcon>
      </button>
      <button
        v-if="isProfileExist"
        tabindex="-1"
        class="profile-btn"
        :class="{
          'has-reminder': hasFailedTransmissions,
        }"
        @click="toggleProfile"
        data-e2e="show-account-button"
      >
        <ProfileIcon />
      </button>
    </div>
    <div v-else class="base-search__wrapper">
      <button
        tabindex="-1"
        class="back-btn"
        @click="toggleSearch"
        data-e2e="hide-search"
      >
        <ArrowLeftIcon width="24" />
      </button>
      <input
        :id="id"
        :inputmode="inputMode"
        :value="modelValue"
        @input="updateInput"
        @focus="updateFocus"
        @blur="updateFocus"
        :placeholder="placeholderTitle"
        class="base-search__input"
        data-e2e="search-input"
        ref="searchInput"
      />
      <transition name="md-input-action" appear>
        <button
          tabindex="-1"
          class="clear-btn"
          @click="clearInput"
          v-if="hasValue && textClearable"
          data-e2e="clear-search-input"
        >
          <ClearIcon />
        </button>
      </transition>
      <div v-if="showBarcodeBlock" class="barcode-scan-block">
        <BarcodeScannerIcon width="20" />
      </div>
      <button
        v-if="!showSearch"
        tabindex="-1"
        class="search-btn"
        @click="toggleSearch"
        data-e2e="toggle-search"
      >
        <SearchIcon />
      </button>
    </div>

    <Popup
      @close="toggleProfile"
      :visible="visibleProfile"
      isSpaceToContentExist
      fullWidth
    >
      <template #default>
        <Profile @update="updateData" />
      </template>
    </Popup>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { Popup } from '@/features/ui';
import {
  ArrowLeftIcon,
  BarcodeScannerIcon,
  BaseIcon,
  ClearIcon,
  ProfileIcon,
  SearchIcon,
} from '@/features/ui/icons';
import BackButton from '@/features/ui/components/BackButton.vue';
// NOTE:  Failed to resolve component, when importing via barrel
import Profile from '@/features/login/views/Profile.vue';
import { BarcodeScanEvent } from '@/features/orders/checkin';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { useTransmissions } from '@/features/transmissions';

export default defineComponent({
  name: 'Search',
  props: {
    searchTitle: {
      type: String,
      default: '',
    },
    placeholderTitle: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    textClearable: {
      type: Boolean,
      default: true,
    },
    showBarcodeBlock: {
      type: Boolean,
      default: false,
    },
    searchDisabled: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: 'text',
    },
    isBackExist: {
      type: Boolean,
      default: false,
    },
    isProfileExist: {
      type: Boolean,
      default: true,
    },
    backTo: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: '/',
    },
  },
  components: {
    Profile,
    ArrowLeftIcon,
    ClearIcon,
    SearchIcon,
    ProfileIcon,
    BaseIcon,
    Popup,
    BarcodeScannerIcon,
    BackButton,
  },
  setup(props, { emit }) {
    const { hasFailedTransmissions } = useTransmissions();

    const showSearch = ref(false);
    const visibleProfile = ref(false);
    const hasValue = computed(() =>
      Boolean(
        typeof props.modelValue === 'string'
          ? props.modelValue.length
          : props.modelValue,
      ),
    );
    const searchInput = ref<HTMLInputElement | null>(null);

    const toggleSearch = () => {
      if (props.searchDisabled) {
        return;
      }
      showSearch.value = !showSearch.value;
      if (showSearch.value) {
        void nextTick(() => {
          searchInput.value?.focus();
        });
      } else {
        clearInput();
      }

      emit('search-activated', Boolean(showSearch.value));
    };
    const toggleProfile = () => {
      visibleProfile.value = !visibleProfile.value;
    };

    const closeProfile = () => {
      visibleProfile.value = false;
    };

    const updateData = () => {
      closeProfile();
      emit('update');
    };

    const clearInput = () => {
      emit('update:modelValue', null);
      void nextTick(() => {
        if (searchInput.value) {
          searchInput.value.focus();
        }
      });
    };

    const updateInput = (e: Event) => {
      const target = e.target as HTMLInputElement;

      emit('update:modelValue', target.value);
    };

    const searchIconColor = computed(() =>
      props.searchDisabled ? '#d6d8da' : '#0d3a93',
    );

    const updateFocus = () => {
      emit('focus');
    };

    const subscription = eventBusServicePlugin
      .get()
      .on(BarcodeScanEvent, () => {
        void nextTick(() => {
          if (searchInput.value) {
            searchInput.value.blur();
          }
        });
      });

    onUnmounted(() => {
      if (subscription) {
        subscription.cancel();
      }
    });

    return {
      props,
      showSearch,
      toggleProfile,
      visibleProfile,
      toggleSearch,
      hasValue,
      updateData,
      searchInput,
      clearInput,
      updateInput,
      searchIconColor,
      updateFocus,
      hasFailedTransmissions,
    };
  },
});
</script>
<style lang="scss" scoped>
.icon-btn {
  display: flex;
  padding: 0;
  min-width: 28px;
  background: transparent;
  border: none;
}

.base-search {
  padding: 0 16px;
  margin-bottom: 16px;

  &__title {
    display: grid;
    align-items: center;
    padding: 6px 24px 6px 16px;
    text-align: left;
    background: $search-bg;
    border-radius: $search-border-radius;
    grid-template-columns: 1fr 24px;
    gap: 0 16px;

    &--back {
      grid-template-columns: auto 1fr 24px;
    }

    &--profile {
      grid-template-columns: 1fr repeat(2, 24px);
    }

    .title {
      overflow: hidden;
      font-size: 16px;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.4;
      word-spacing: 0.2em;
    }

    .profile-btn {
      @extend .icon-btn;
      position: relative;
      top: 0;
    }

    .has-reminder::after {
      position: absolute;
      top: 1px;
      right: 1px;
      display: block;
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 50%;
      content: '';
    }

    .search-btn {
      @extend .icon-btn;
      top: 10px;
      right: 61px;
    }
  }

  &__wrapper {
    display: grid;
    padding: 6px 16px;
    color: #000;
    background-clip: padding-box;
    border: $input-border-width solid $input-focus-border-color;
    border-radius: $search-border-radius;
    outline: $input-border-width solid transparent;
    transition: all 0.2s linear;
    transition: all 0.2s linear;
    grid-template-columns: 24px 1fr 24px;
    gap: 0 8px;

    &:focus-within {
      outline-color: $input-focus-border-color;
    }

    .clear-btn {
      @extend .icon-btn;
    }

    .search-btn {
      @extend .icon-btn;
      grid-column-end: -1;
    }

    .back-btn {
      @extend .icon-btn;
    }

    .barcode-scan-block {
      @extend .icon-btn;
      justify-content: center;
      align-items: center;
    }
  }

  &__input {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    font-weight: normal;
    border: none;
    caret-color: #0d3a93;
    transform: translateY(0.1em);

    &::placeholder {
      transform: translateX(4px);
    }

    &:focus,
    &.with-text {
      outline: 0;
    }
  }
}
</style>
