import { AuthService } from '@/features/core/auth';
import { EntityRepository } from '@/features/core/entity-repository';
import { DataSyncScopes, DataSyncService } from '@/features/data-sync/types';
import { DeviceIdService } from '@/features/device-id';
import { OrdersService } from '@/features/orders/services';
import { OrderLocalStatus } from '@/features/orders/types';
import { ProductsService } from '@/features/products/services';
import { PushProvider, PushService } from '@/features/push-notification';
import { SyncSchedulerService } from '@/features/sync-scheduler/services';
import { Device } from '../entities';
import { DeviceRequestPayload } from '../types';

export class DeviceService {
  constructor(
    private entityRepository: EntityRepository,
    private deviceIdService: DeviceIdService,
    private pushService: PushService,
    private authService: AuthService,
    private syncSchedulerService: SyncSchedulerService,
    private dataSyncService: DataSyncService,
    private ordersService: OrdersService,
    private productsService: ProductsService,
  ) {
    void this.setDeviceId();
    this.pushProvider = this.pushService.getProvider();
  }

  private deviceId = 'Unknown';
  private pushProvider: PushProvider | null = null;

  async updateAndSync(): Promise<void> {
    const isDeviceStored = await this.checkIfDeviceStored();
    const pushToken = await this.getPushToken();
    const lastLogin = await this.getTokenCreationTime();

    await this.entityRepository.save(
      Device.from({
        type: 'device',
        id: this.deviceId,
        lastLogin,
        pushToken,
        appVersion: process.env.VUE_APP_VERSION,
        isRegistered: isDeviceStored,
      }),
      { waitForSync: true },
    ).completed;
  }

  async gatherDeviceInfo(): Promise<DeviceRequestPayload> {
    const numberOfStoredOrders = await this.ordersService.getOrdersCount();
    const numberOfStoredProducts =
      await this.productsService.getProductsCount();
    const ongoingTransactions =
      await this.syncSchedulerService.getPendingSyncs();
    const failedTransactions = await this.syncSchedulerService.getFailedSyncs();
    const numberOfOrdersInProgress = await this.ordersService.getOrdersCount({
      filter: {
        localStatus: { equals: OrderLocalStatus.PickingInProgress },
      },
    });
    const productsDataSync = await this.dataSyncService.getById(
      DataSyncScopes.ProductSync,
    );
    return {
      deviceId: this.deviceId,
      numberOfStoredOrders,
      numberOfStoredProducts,
      numberOfOngoingTransactions: ongoingTransactions.length,
      numberOfFailedTransactions: failedTransactions.length,
      numberOfOrdersInProgress,
      lastFullSyncOfProducts: productsDataSync?.lastSync || 'Unknown',
    };
  }

  private async getPushToken(): Promise<string> {
    return this.pushProvider ? await this.pushProvider.getToken() : 'Unknown';
  }

  private async getTokenCreationTime(): Promise<string> {
    const tokenCreationTime = await this.authService.getUserTokenCreationTime();
    return tokenCreationTime || 'Unknown';
  }

  private async checkIfDeviceStored(): Promise<boolean> {
    const devices = await this.entityRepository.getAll(Device);
    const device = devices.value[0];
    if (!device) {
      return false;
    }
    if (device.id !== this.deviceId) {
      // remove stored device entry with old deviceId
      this.entityRepository.remove(device);
      return false;
    }
    return true;
  }

  private async setDeviceId() {
    this.deviceId = (await this.deviceIdService.getDeviceId()) || 'Unknown';
  }
}
