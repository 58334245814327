import { PipelinePlugin } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { deviceApiPlugin } from '../api';
import { deviceServicePlugin } from '../services';

export class UpdateDeviceInfoPlugin<T> implements PipelinePlugin<T> {
  constructor(private shouldRunAsync: boolean = false) {}

  public async execute(dto: T): Promise<T> {
    const deviceInfo = await deviceServicePlugin.get().gatherDeviceInfo();

    if (this.shouldRunAsync) {
      deviceApiPlugin
        .get()
        .updateDevice(deviceInfo)
        .catch(() => {
          loggerServicePlugin
            .get()
            .error(
              'Failed to update device info during start picking processing',
            );
        });
    } else {
      await deviceApiPlugin.get().updateDevice(deviceInfo);
    }

    return dto;
  }
}
