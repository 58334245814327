import type { Transmission } from '../types';
import { ReportsService } from '@/features/reports';

export class FailedTransmissionsService {
  constructor(private reportService: ReportsService) {}

  async sendFailedTransmissionReports(
    failedTransmissions: Transmission[],
  ): Promise<Transmission[]> {
    const length = failedTransmissions.length;
    const reportedTransmissions: Transmission[] = [];

    for (let i = 0; i < length; i++) {
      const transmission: Transmission = failedTransmissions[i];

      if (transmission.reportSent) {
        continue;
      }

      const result = await this.reportService.sendErrorReport(transmission);
      if (!result) {
        continue;
      }

      reportedTransmissions.push(transmission);
    }

    return reportedTransmissions;
  }
}
